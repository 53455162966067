import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Lang = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    document.activeElement.blur();
  };

  const languageCondition = () => {
    if (i18n.language === "tm") {
      return (
        <span className="flex items-center gap-x-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 36 36"
            aria-hidden="true"
            role="img"
            className="size-5 -mt-0.5"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              fill="#29AE66"
              d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z"
            />
            <path fill="#CA3745" d="M4 5h7v26H4z" />
            <path
              fill="#FAAE29"
              d="M4 8.312l.805.805l-.219.219l.32.32l.211-.211l.664.664l-.656.657l-.273-.274l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.607l.351.304l.328-.328l-.351-.351l-.246.246l-.641-.641l.24-.24L4 8.688zm0 4.626l.805.804l-.219.219l.32.32l.211-.211l.664.664l-.656.657l-.273-.274l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.607l.351.304l.328-.328l-.351-.351l-.246.246l-.641-.641l.24-.24L4 13.312zm0 4.718l.805.805l-.219.219l.32.32l.211-.211l.664.664l-.656.656l-.273-.273l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.608l.351.305l.328-.328l-.351-.352l-.246.246l-.641-.64l.24-.24L4 18.031zm0 4.656l.805.805l-.219.219l.32.32l.211-.211l.664.664l-.656.657l-.273-.274l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.607l.351.304l.328-.328l-.351-.351l-.246.246l-.641-.641l.24-.24L4 22.688zm7-14l-.805.805l.219.219l-.32.32l-.211-.211l-.664.664l.656.657l.273-.274l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.607l-.351.304l-.328-.328l.351-.351l.246.246l.641-.641l-.24-.24l.492-.445zM4.562 5.688l.29-.29l.273.274l.656-.656L5.766 5h-.301l.025.025l-.328.329l-.351-.305l-.608.607l.238.192L4 6.266v.375l.805-.711zm6.235-.032l-.608-.607l-.351.305l-.328-.329L9.535 5h-.301l-.015.016l.656.656l.273-.274l.29.29l-.243.242l.805.711v-.375l-.441-.418zM11 12.938l-.805.804l.219.219l-.32.32l-.211-.211l-.664.664l.656.657l.273-.274l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.607l-.351.304l-.328-.328l.351-.351l.246.246l.641-.641l-.24-.24l.492-.446zm0 4.718l-.805.805l.219.219l-.32.32l-.211-.211l-.664.664l.656.656l.273-.273l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.608l-.351.305l-.328-.328l.351-.352l.246.246l.641-.64l-.24-.24l.492-.446zm0 4.656l-.805.805l.219.219l-.32.32l-.211-.211l-.664.664l.656.657l.273-.274l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.607l-.351.304l-.328-.328l.351-.351l.246.246l.641-.641l-.24-.24l.492-.445z"
            />
            <path
              fill="#29AE66"
              d="M10.021 6.938v-.396l-.583-.354H9l-.708-.368H6.708L6 6.188h-.438l-.583.354v.396l-.541.333v.917l.541.333v.396l.583.354H6l.708.368h1.584L9 9.271h.438l.583-.354v-.396l.541-.333v-.917z"
            />
            <path
              fill="#FAAE29"
              d="M4.928 7.728v-.391l.462-.285v-.337l.498-.303h.374l.604-.313h.676v1.629z"
            />
            <path
              fill="#FFF"
              d="M4.887 7.729v.392l.462.284v.338l.498.302h.373l.605.313H7.5V7.729zm5.226 0v-.391l-.462-.284v-.338l-.498-.302H8.78l-.605-.313H7.5v1.628z"
            />
            <path
              fill="#FAAE29"
              d="M10.113 7.729v.392l-.462.284v.338l-.498.302H8.78l-.605.313H7.5V7.729zm-5.185 3.833h.676v.729h-.676zm.676-.104h.676v.729h-.676zm.677-.26h.676v.729h-.676zm3.135.364h.676v.729h-.676zm-.677-.104h.676v.729h-.676zm-.677-.26h.676v.729h-.676z"
            />
            <path
              fill="#FAAE29"
              d="M6.958 10.833h1.105v.901H6.958zm-2.03 1.651h.676v.729h-.676zm.676.104h.676v.729h-.676zm.677.261h.676v.729h-.676zm3.135-.365h.676v.729h-.676zm-.677.104h.676v.729h-.676zm-.677.261h.676v.729h-.676z"
            />
            <path fill="#FAAE29" d="M6.958 13.042h1.105v.901H6.958z" />
            <path
              fill="#FFF"
              d="M8.549 24.608H6.491l-1.456.932v1.316l1.456.931h2.058l1.456-.931V25.54z"
            />
            <path
              fill="#29AE66"
              d="M8.383 15.646H6.657l-1.221.86v1.217l1.221.86h1.727l1.221-.86v-1.217l-1.222-.86zm.736 1.936l-.937.66H6.858l-.937-.66v-.934l.937-.66h1.324l.937.66v.934zm-.526 6.96H6.447l-1.517.971v1.371l1.517.97h2.145l1.517-.97v-1.371l-1.516-.971zm1.059 2.221l-1.25.799H6.637l-1.25-.799v-1.13l1.25-.799h1.766l1.25.799v1.13zm.102-5.325v-.443h-.442v-.493H5.719v.493h-.494v.443h-.433v.646h.433v.479h.494v.521h3.594v-.521h.441v-.479h.517v-.646h-.517zm-.677 1.374H5.959v-2.084h3.118v2.084z"
            />
            <path
              fill="#29AE66"
              d="M7.5 20.25l-1.896 1.125v.729l1.896 1.23l1.916-1.188v-.771z"
            />
            <path
              fill="#CA3745"
              d="M6.651 25.766l.849-.491l.848.491v.979l-.848.489l-.849-.489z"
            />
            <path
              fill="#FAAE29"
              d="M8.348 30.084l-.244.166s-.208-.396-.771-.438s-.875.188-1.354.219c-.479.031-1.955-1.086-1.188-2.797c.688.495.331 1.099.645 1.391c.314.292.645.49.772.834c.128.344.726.059 1.125.125c.625.104 1.015.5 1.015.5z"
            />
            <path
              fill="#FAAE29"
              d="M6.651 30.084l.244.166s.208-.396.771-.438s.876.188 1.355.219s1.955-1.086 1.187-2.797c-.687.495-.331 1.099-.645 1.391c-.314.292-.645.49-.772.834c-.128.344-.726.059-1.125.125c-.625.104-1.015.5-1.015.5z"
            />
            <path
              fill="#FFF"
              d="M18.18 8.289a3.058 3.058 0 0 1 1.07 2.316c0 1.7-1.378 3.078-3.078 3.078a3.05 3.05 0 0 1-1.591-.455a3.105 3.105 0 0 0 2.294 1.018A3.125 3.125 0 0 0 20 11.121c0-1.26-.748-2.337-1.82-2.832zm-2.334-.422l-.246.483l.383.384l-.535-.085l-.247.483l-.084-.535l-.536-.085l.483-.246l-.085-.537l.384.384z"
            />
            <path
              fill="#FFF"
              d="M17.377 8.795l-.246.483l.384.384l-.536-.085l-.247.483l-.084-.536l-.536-.085l.483-.246l-.084-.536l.383.384zm-1.531.665l-.246.483l.383.384l-.535-.085l-.247.483l-.084-.536l-.536-.085l.483-.246l-.085-.536l.384.384zm-1.594.809l-.246.483l.384.384l-.536-.085l-.247.484l-.084-.536l-.536-.085l.483-.246l-.084-.536l.383.384zm1.594.902l-.246.483l.383.384l-.535-.085l-.247.483l-.084-.536l-.536-.085l.483-.246l-.085-.536l.384.384z"
            />
            <path
              fill="#CA3745"
              d="M7.062 20.771h.915v.615h-.915zm0 1.401h.915v.615h-.915z"
            />
            <path
              fill="#FAAE29"
              d="M5.604 21.484h1.253v.615H5.604zm2.584 0h1.228v.615H8.188z"
            />
          </svg>
          TM
        </span>
      );
    } else if (i18n.language === "ru") {
      return (
        <span className="flex items-center gap-x-1.5">
          {" "}
          <svg
            className="size-5 -mt-0.5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path fill="#1435a1" d="M1 11H31V21H1z"></path>
            <path
              d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
              fill="#fff"
            ></path>
            <path
              d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
              transform="rotate(180 16 24)"
              fill="#c53a28"
            ></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"
            ></path>
            <path
              d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff"
              opacity=".2"
            ></path>
          </svg>
          RU
        </span>
      );
    } else if (i18n.language === "en") {
      return (
        <span className="flex items-center gap-x-1.5">
          <svg
            className="size-5 -mt-0.5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <rect
              x="1"
              y="4"
              width="30"
              height="24"
              rx="4"
              ry="4"
              fill="#071b65"
            ></rect>
            <path
              d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
              fill="#fff"
            ></path>
            <path
              d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
              fill="#b92932"
            ></path>
            <path
              d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
              fill="#b92932"
            ></path>
            <path
              d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
              fill="#fff"
            ></path>
            <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
            <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
            <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
            <rect
              x="14"
              y="1"
              width="4"
              height="30"
              transform="translate(32) rotate(90)"
              fill="#b92932"
            ></rect>
            <path
              d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
              fill="#b92932"
            ></path>
            <path
              d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
              fill="#b92932"
            ></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"
            ></path>
            <path
              d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff"
              opacity=".2"
            ></path>
          </svg>
          EN
        </span>
      );
    }
  };

  return (
    <div
      id="languages"
      tabIndex={-1}
      className="relative group inline-block text-left me-4"
    >
      <button
        type="button"
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        id="menu-button"
        aria-haspopup="true"
      >
        {languageCondition()}
        <svg
          className="-mr-1 size-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div
        className="absolute hidden group-focus-within:block right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none w-[100%]"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      >
        <div role="none">
          <button
            className={`flex gap-x-1.5 items-center px-4 py-2 text-gray-700 w-full hover:bg-gray-100/50 ${
              i18n.language === "tm" ? "hidden" : ""
            }`}
            role="menuitem"
            id="menu-item-0"
            onClick={() => changeLanguage("tm")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 36 36"
              aria-hidden="true"
              role="img"
              className="size-5"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                fill="#29AE66"
                d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z"
              />
              <path fill="#CA3745" d="M4 5h7v26H4z" />
              <path
                fill="#FAAE29"
                d="M4 8.312l.805.805l-.219.219l.32.32l.211-.211l.664.664l-.656.657l-.273-.274l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.607l.351.304l.328-.328l-.351-.351l-.246.246l-.641-.641l.24-.24L4 8.688zm0 4.626l.805.804l-.219.219l.32.32l.211-.211l.664.664l-.656.657l-.273-.274l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.607l.351.304l.328-.328l-.351-.351l-.246.246l-.641-.641l.24-.24L4 13.312zm0 4.718l.805.805l-.219.219l.32.32l.211-.211l.664.664l-.656.656l-.273-.273l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.608l.351.305l.328-.328l-.351-.352l-.246.246l-.641-.64l.24-.24L4 18.031zm0 4.656l.805.805l-.219.219l.32.32l.211-.211l.664.664l-.656.657l-.273-.274l-.29.289l.243.242l-.805.711v-.375l.441-.418l-.238-.191l.608-.607l.351.304l.328-.328l-.351-.351l-.246.246l-.641-.641l.24-.24L4 22.688zm7-14l-.805.805l.219.219l-.32.32l-.211-.211l-.664.664l.656.657l.273-.274l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.607l-.351.304l-.328-.328l.351-.351l.246.246l.641-.641l-.24-.24l.492-.445zM4.562 5.688l.29-.29l.273.274l.656-.656L5.766 5h-.301l.025.025l-.328.329l-.351-.305l-.608.607l.238.192L4 6.266v.375l.805-.711zm6.235-.032l-.608-.607l-.351.305l-.328-.329L9.535 5h-.301l-.015.016l.656.656l.273-.274l.29.29l-.243.242l.805.711v-.375l-.441-.418zM11 12.938l-.805.804l.219.219l-.32.32l-.211-.211l-.664.664l.656.657l.273-.274l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.607l-.351.304l-.328-.328l.351-.351l.246.246l.641-.641l-.24-.24l.492-.446zm0 4.718l-.805.805l.219.219l-.32.32l-.211-.211l-.664.664l.656.656l.273-.273l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.608l-.351.305l-.328-.328l.351-.352l.246.246l.641-.64l-.24-.24l.492-.446zm0 4.656l-.805.805l.219.219l-.32.32l-.211-.211l-.664.664l.656.657l.273-.274l.29.289l-.243.242l.805.711v-.375l-.441-.418l.238-.191l-.608-.607l-.351.304l-.328-.328l.351-.351l.246.246l.641-.641l-.24-.24l.492-.445z"
              />
              <path
                fill="#29AE66"
                d="M10.021 6.938v-.396l-.583-.354H9l-.708-.368H6.708L6 6.188h-.438l-.583.354v.396l-.541.333v.917l.541.333v.396l.583.354H6l.708.368h1.584L9 9.271h.438l.583-.354v-.396l.541-.333v-.917z"
              />
              <path
                fill="#FAAE29"
                d="M4.928 7.728v-.391l.462-.285v-.337l.498-.303h.374l.604-.313h.676v1.629z"
              />
              <path
                fill="#FFF"
                d="M4.887 7.729v.392l.462.284v.338l.498.302h.373l.605.313H7.5V7.729zm5.226 0v-.391l-.462-.284v-.338l-.498-.302H8.78l-.605-.313H7.5v1.628z"
              />
              <path
                fill="#FAAE29"
                d="M10.113 7.729v.392l-.462.284v.338l-.498.302H8.78l-.605.313H7.5V7.729zm-5.185 3.833h.676v.729h-.676zm.676-.104h.676v.729h-.676zm.677-.26h.676v.729h-.676zm3.135.364h.676v.729h-.676zm-.677-.104h.676v.729h-.676zm-.677-.26h.676v.729h-.676z"
              />
              <path
                fill="#FAAE29"
                d="M6.958 10.833h1.105v.901H6.958zm-2.03 1.651h.676v.729h-.676zm.676.104h.676v.729h-.676zm.677.261h.676v.729h-.676zm3.135-.365h.676v.729h-.676zm-.677.104h.676v.729h-.676zm-.677.261h.676v.729h-.676z"
              />
              <path fill="#FAAE29" d="M6.958 13.042h1.105v.901H6.958z" />
              <path
                fill="#FFF"
                d="M8.549 24.608H6.491l-1.456.932v1.316l1.456.931h2.058l1.456-.931V25.54z"
              />
              <path
                fill="#29AE66"
                d="M8.383 15.646H6.657l-1.221.86v1.217l1.221.86h1.727l1.221-.86v-1.217l-1.222-.86zm.736 1.936l-.937.66H6.858l-.937-.66v-.934l.937-.66h1.324l.937.66v.934zm-.526 6.96H6.447l-1.517.971v1.371l1.517.97h2.145l1.517-.97v-1.371l-1.516-.971zm1.059 2.221l-1.25.799H6.637l-1.25-.799v-1.13l1.25-.799h1.766l1.25.799v1.13zm.102-5.325v-.443h-.442v-.493H5.719v.493h-.494v.443h-.433v.646h.433v.479h.494v.521h3.594v-.521h.441v-.479h.517v-.646h-.517zm-.677 1.374H5.959v-2.084h3.118v2.084z"
              />
              <path
                fill="#29AE66"
                d="M7.5 20.25l-1.896 1.125v.729l1.896 1.23l1.916-1.188v-.771z"
              />
              <path
                fill="#CA3745"
                d="M6.651 25.766l.849-.491l.848.491v.979l-.848.489l-.849-.489z"
              />
              <path
                fill="#FAAE29"
                d="M8.348 30.084l-.244.166s-.208-.396-.771-.438s-.875.188-1.354.219c-.479.031-1.955-1.086-1.188-2.797c.688.495.331 1.099.645 1.391c.314.292.645.49.772.834c.128.344.726.059 1.125.125c.625.104 1.015.5 1.015.5z"
              />
              <path
                fill="#FAAE29"
                d="M6.651 30.084l.244.166s.208-.396.771-.438s.876.188 1.355.219s1.955-1.086 1.187-2.797c-.687.495-.331 1.099-.645 1.391c-.314.292-.645.49-.772.834c-.128.344-.726.059-1.125.125c-.625.104-1.015.5-1.015.5z"
              />
              <path
                fill="#FFF"
                d="M18.18 8.289a3.058 3.058 0 0 1 1.07 2.316c0 1.7-1.378 3.078-3.078 3.078a3.05 3.05 0 0 1-1.591-.455a3.105 3.105 0 0 0 2.294 1.018A3.125 3.125 0 0 0 20 11.121c0-1.26-.748-2.337-1.82-2.832zm-2.334-.422l-.246.483l.383.384l-.535-.085l-.247.483l-.084-.535l-.536-.085l.483-.246l-.085-.537l.384.384z"
              />
              <path
                fill="#FFF"
                d="M17.377 8.795l-.246.483l.384.384l-.536-.085l-.247.483l-.084-.536l-.536-.085l.483-.246l-.084-.536l.383.384zm-1.531.665l-.246.483l.383.384l-.535-.085l-.247.483l-.084-.536l-.536-.085l.483-.246l-.085-.536l.384.384zm-1.594.809l-.246.483l.384.384l-.536-.085l-.247.484l-.084-.536l-.536-.085l.483-.246l-.084-.536l.383.384zm1.594.902l-.246.483l.383.384l-.535-.085l-.247.483l-.084-.536l-.536-.085l.483-.246l-.085-.536l.384.384z"
              />
              <path
                fill="#CA3745"
                d="M7.062 20.771h.915v.615h-.915zm0 1.401h.915v.615h-.915z"
              />
              <path
                fill="#FAAE29"
                d="M5.604 21.484h1.253v.615H5.604zm2.584 0h1.228v.615H8.188z"
              />
            </svg>
            TM
          </button>
          <button
            className={`flex gap-x-1.5 items-center px-4 py-2 text-gray-700 w-full hover:bg-gray-100/50 ${
              i18n.language === "ru" ? "hidden" : ""
            }`}
            role="menuitem"
            id="menu-item-0"
            onClick={() => changeLanguage("ru")}
          >
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path fill="#1435a1" d="M1 11H31V21H1z"></path>
              <path
                d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                fill="#fff"
              ></path>
              <path
                d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                transform="rotate(180 16 24)"
                fill="#c53a28"
              ></path>
              <path
                d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                opacity=".15"
              ></path>
              <path
                d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                fill="#fff"
                opacity=".2"
              ></path>
            </svg>
            RU
          </button>
          <button
            className={`flex gap-x-1.5 items-center px-4 py-2 text-gray-700 w-full hover:bg-gray-100/50 ${
              i18n.language === "en" ? "hidden" : ""
            }`}
            role="menuitem"
            id="menu-item-0"
            onClick={() => changeLanguage("en")}
          >
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <rect
                x="1"
                y="4"
                width="30"
                height="24"
                rx="4"
                ry="4"
                fill="#071b65"
              ></rect>
              <path
                d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
                fill="#fff"
              ></path>
              <path
                d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
                fill="#b92932"
              ></path>
              <path
                d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
                fill="#b92932"
              ></path>
              <path
                d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                fill="#fff"
              ></path>
              <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
              <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
              <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
              <rect
                x="14"
                y="1"
                width="4"
                height="30"
                transform="translate(32) rotate(90)"
                fill="#b92932"
              ></rect>
              <path
                d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
                fill="#b92932"
              ></path>
              <path
                d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
                fill="#b92932"
              ></path>
              <path
                d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                opacity=".15"
              ></path>
              <path
                d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                fill="#fff"
                opacity=".2"
              ></path>
            </svg>
            EN
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lang;
